/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs';
import { IAuth } from '@sybl/feature-auth-models';

@Injectable()
export class AdminUserHttpService {
  private dataApiServer: string;
  private authServer: string;

  constructor(@Inject('appUrls') appUrls: any, private http: HttpClient) {
    this.dataApiServer = appUrls.API_HTTPS_SERVER;
    this.authServer = appUrls.AUTH_URL;
  }

  userFilterSearch(filterForm) {
    const apiUrl =
      this.dataApiServer + '/api-server/data-admin/users/get-filtered-pagination';

    const filters = JSON.stringify(filterForm.filters);
    const pageIndex = filterForm.pageIndex;
    const pageSize = filterForm.pageSize;
    const searchMultipleProperties = filterForm.searchMultipleProperties;
    const searchOperator = filterForm.searchOperator;
    const searchOperatorTwo = filterForm.searchOperatorTwo;
    const sortBy = filterForm.sortBy;
    const sortDirection = filterForm.sortDirection;

    const options = {
      params: new HttpParams()
        .set('filters', filters)
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('searchMultipleProperties', searchMultipleProperties)
        .set('searchOperator', searchOperator)
        .set('searchOperatorTwo', searchOperatorTwo)
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection),
    };
    //return this.http.post(apiUrl, filterForm, { withCredentials: true })

    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {
        // If did not respond with a user return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        } else return response;
      }),
      catchError((err) => {
        console.log('Error on Company', err);
        return err;
      })
    );
  }

  userSelectedPropSearch(searchForm) {
    const apiUrl =
      this.dataApiServer + '/api-server/data-admin/users/get-selected-field-pagination';

    const searchField = searchForm.searchField;
    const fieldOne = searchForm.fieldOne;
    const pageIndex = searchForm.pageIndex;
    const pageSize = searchForm.pageSize;
    const searchMultipleProperties = searchForm.searchMultipleProperties;
    const searchOperator = searchForm.searchOperator;
    const searchOperatorTwo = searchForm.searchOperatorTwo;
    const sortBy = searchForm.sortBy;
    const sortDirection = searchForm.sortDirection;

    const options = {
      params: new HttpParams()
        .set('pageIndex', pageIndex)
        .set('pageSize', pageSize)
        .set('fieldOne', fieldOne)
        .set('searchField', searchField)
        .set('searchMultipleProperties', searchMultipleProperties)
        .set('searchOperator', searchOperator)
        .set('searchOperatorTwo', searchOperatorTwo)
        .set('sortBy', sortBy)
        .set('sortDirection', sortDirection),
    };
    //return this.http.post(apiUrl, searchForm, { withCredentials: true })

    return this.http.get(apiUrl, options).pipe(
      map((response: any) => {
        // If did not respond with a user return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        } else return response;
      }),
      catchError((err) => {
        console.log('Error on Company', err);
        return err;
      })
    );
  }

  deleteUser(user_id) {
    const body = {
      user_id: user_id,
    };
    const url = this.authServer + '/auth/admin/delete-user';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getUser(_id: string) {
    const url = this.authServer + '/auth/admin/get-user';
    const params = new HttpParams().set('_id', _id);
    const options = {
      params: params,
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      })
    );
  }

  changeUserPassword(email, password) {
    const body = {
      email: email,
      password: password,
    };

    const url = this.authServer + '/auth/admin/change-user-password';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveUser(user: IAuth) {
    const body = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      permissions: user.permissions,
    };

    const url = this.authServer + '/auth/admin/edit-user';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
