import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs';

@Injectable()
export class StripeHttpService {
  private authServer: string;

  constructor(@Inject('appUrls') appUrls: any, private http: HttpClient) {
    this.authServer = appUrls.AUTH_URL;
  }

  checkOutProduct(product){
    const apiUrl = this.authServer + '/auth/shopping/checkout/create';
      console.log("stripe Service Checkout", product)
    return this.http.post(apiUrl, product, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }



  createPaymentIntent(amount){
    const apiUrl = this.authServer + '/auth/stripe/create-payment-intent';
    return this.http.post(apiUrl, {amount}, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  

  sendDonationReceiptEmail(donationInfo){
    const apiUrl = this.authServer + '/auth/donation-receipt/email';

    console.log("donationInfo Email", donationInfo)
    return this.http.post(apiUrl, {donationInfo}, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
   
  
  sendDonationReceipt(donationInfo){
    const apiUrl = this.authServer + '/auth/donations/save';

    console.log("donation Receipt", donationInfo)
    return this.http.post(apiUrl, {donationInfo}, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}



