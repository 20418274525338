
import { Injectable } from '@angular/core';
import { IFormattedSearchProperty, IMatTableColumn } from '@sybl/common-models';

@Injectable()
export class ApplyAdvancedSearchParamaters {
    _newAdvancedSearchForm: any;
    _passedParamaters: any;

    updateForm(passedFormConstructor:any, passedParamaters:any) {
        this._newAdvancedSearchForm = { ...passedFormConstructor };
        this._passedParamaters = passedParamaters;
        const searchVariables: Array<any> = [];
        const getKeyNames = Object.getOwnPropertyNames(this._newAdvancedSearchForm);

        getKeyNames.filter(categoryName => categoryName !== '_minCellWidth').map(categoryName => {

            this._newAdvancedSearchForm[categoryName].map((prop:any, index:number) => {
                // Get the propertyName by grabbing the keys from the passed this._passedParamaters
                const propName = prop.key;

                // Get the 
                const propValue = this._passedParamaters[categoryName][propName];


                if (categoryName !== 'searchVariables' && propValue !== "" && propValue !== undefined && propValue != null) {
                    // Need to find the index of this where the categoryName is 
                    const findIndex = this._newAdvancedSearchForm[categoryName].findIndex((props:any) => props.key === propName)
                    this._newAdvancedSearchForm[categoryName][findIndex].value = propValue;
                }
                else if (categoryName === 'searchVariables') {
                    //Run through all of the properties for the categoryName.                                


                    // This is entirely necesssary for searchVariables because of a fundamental timing issue with Class Creation and JS at the moment
                    const searchVariablePropValue = this._passedParamaters['searchVariables'][propName];
                    Object.freeze(searchVariablePropValue);
                    this._newAdvancedSearchForm[categoryName][index].value = searchVariablePropValue;

                    const slow: IMatTableColumn = {
                        key: this._newAdvancedSearchForm[categoryName][index].key,
                        value: searchVariablePropValue,
                        category: categoryName,
                        search_id: this._newAdvancedSearchForm[categoryName][index].search_id,
                        controlType: this._newAdvancedSearchForm[categoryName][index].controlType,
                        label: this._newAdvancedSearchForm[categoryName][index].label,
                        cell: this._newAdvancedSearchForm[categoryName][index].cell,
                        required: this._newAdvancedSearchForm[categoryName][index].required,
                        order: this._newAdvancedSearchForm[categoryName][index].order,
                        options: this._newAdvancedSearchForm[categoryName][index].options
                    }
                    searchVariables.push(slow)
                }
                else {
                    const findIndex = this._newAdvancedSearchForm[categoryName].findIndex((props: { key: any; }) => props.key === propName)
                    this._newAdvancedSearchForm[categoryName][findIndex].value = false;
                }
            })
        })
  
        return { ...this._newAdvancedSearchForm, searchVariables: searchVariables }
    }

    removeSelectedSearchProperty(searchForm: { [x: string]: any[]; }, removeSearchProperty: IFormattedSearchProperty) {
        const propertyKey = removeSearchProperty.key;
        const searchPropIndex = [removeSearchProperty.index][0]
        const updatedCategory = searchForm[propertyKey].map((prop: any, i: any) => {
            if (searchPropIndex === i) { return { ...prop, value: false } }
            else return prop;
        })
        const formData = { ...searchForm, [propertyKey]: updatedCategory }

        return formData
    }


    getSelectedColumnsFromTableColumnsDialog(options: any) {
        let newTableColumns = {}
        const getFormObjectKeys = Object.keys(options).filter(cat => {
            // Run through all of the keys on the submitted form except for searchVariables as they follow a different pattern.
            if (cat !== '__proto__' && cat !== 'searchVariables') {
                return cat;
            }return null

        })


        getFormObjectKeys.map(category => {
            const catArray: { [x: string]: { value: boolean; }; }[] = [];
            let newTableColumnCat = {};

            const categoryPropNames = Object.getOwnPropertyNames(options[category]);
            categoryPropNames.map(prop => {

                if (options[category][prop] === true) {
                    catArray.push({ [prop]: { value: true } })
                }
            })

            catArray.map((catPushed:any) => {
                newTableColumnCat = Object.assign({}, newTableColumnCat, ...catPushed)
            })

            newTableColumns = { ...newTableColumns, [category]: newTableColumnCat }
        })
        return newTableColumns
    }


    getSelectedSearchColumnsFromAdvanced(searchForm: { [x: string]: IMatTableColumn[]; }): any { // Might break this out into a service...
        let newTableColumns: any = {}

        const getFormObjectKeys = Object.keys(searchForm).filter(cat => {
            // Run through all of the keys on the submitted form except for searchVariables as they follow a different pattern.
            if (cat !== '__proto__' && cat !== 'searchVariables') {
                return cat;
            }return null

        })


        //Run through all of the Categories in the form which are populated by checkboxes for search purposes.
        getFormObjectKeys.filter(cat => cat !== 'searchVariables').map(cat => {
            let tableColumnCat:any = {};
            let newTableColumnCat:any = {};

            if (searchForm[cat] != null && searchForm[cat] !== undefined) {
                const catArray: object[] = [];

                searchForm[cat].map((prop: IMatTableColumn, index: any) => {

                    if (prop['value'] === true) {
                        const propName = prop.key as string;

                        tableColumnCat = { ...tableColumnCat[cat], [propName]: { value: true } }

                        catArray.push({ [propName]: true })
                    }
                })

                catArray.map((catPushed:any) => {
                    newTableColumnCat = Object.assign({}, newTableColumnCat, ...catPushed)
                })

                newTableColumns = { ...newTableColumns, [cat]: newTableColumnCat }

            }
        })
        return newTableColumns
    }
}