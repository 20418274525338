/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs';

@Injectable()
export class AdminSyblHttpService {
  private dataApiServer: string;

  constructor(@Inject('appUrls') appUrls: any, private http: HttpClient) {
    this.dataApiServer = appUrls.API_HTTPS_SERVER;
  }

  saveSyblContext(form, jwt) {
    const body = {
      uuid: form.uuid,
      text: form.text,
      context_filter: form.context_filter,
      date_time: form.date_time,
      jwt: jwt,
    };
    const url = this.dataApiServer + '/api-server/data-admin/sybl-context/save';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveSyblContextBasket(form, jwt) {
    const body = {
      uuid: form.uuid,
      text: form.text,
      contexts: form.contexts,
      basket_id: form.basket_id,
      date_time: form.date_time,
      jwt: jwt,
    };
    const url = this.dataApiServer + '/api-server/data-admin/sybl-context-basket/save';
    return this.http.post(url, body, { withCredentials: true }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getAllContexts() {
    const url = this.dataApiServer + '/api-server/data-admin/sybl-context/getAll';
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      })
    );
  }


  getContextByUuid(uuid:string) {
    const url = this.dataApiServer + '/api-server/data-admin/sybl-context/getByUuid';
    const params = new HttpParams().set('uuid', uuid);
    const options = {
        params:params
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      })
    );
  }

  getContextByContextId(context_filter:string) {
    const url = this.dataApiServer + '/api-server/data-admin/sybl-context/getByContextId';
    const params = new HttpParams().set('context_filter', context_filter);
    const options = {
        params:params
    };

    return this.http.get<any>(url,options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      })
    );
  }

  getAllBaskets() {
    const url = this.dataApiServer + '/api-server/data-admin/sybl-context-basket/getAll';
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      })
    );
  }

  getBasketByUuid(uuid:string) {
    const url = this.dataApiServer + '/api-server/data-admin/sybl-context-basket/getByUuid';
    const params = new HttpParams().set('uuid', uuid);
    const options = {
        params:params
    };

    return this.http.get<any>(url, options).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        return err; //
      })
    );
  }

}
