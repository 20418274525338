/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormObjectBase } from '@sybl/common-models';
  
@Injectable() 
export class ConvertFromClassToMatFormService {

  toFormGroup(sections: FormObjectBase<any>[]) {
    const group: any = {};

    sections.forEach(control => {

      group[control.key] = control.required ? new FormControl(control.value || '', Validators.required)
        : new FormControl(control.value || '');
    });
    return new FormGroup(group);
  }

  // Create form Controls based on the passed data
  handleMultipleGroups(passedFormGroup: any) {
    const formGroupNames = Object.getOwnPropertyNames(passedFormGroup);
       const newFormGroupArray = formGroupNames.map(name => {
      const bigGroup: any = {[name]:{}};


      const groups = passedFormGroup[name].map((control: { required: any; value: any; label: any; key: string | number; controlType: any; order: any; styles: any; search_id: any; }) => {
      if (control.required) {
          let formControl = new FormControl(control.value || '', Validators.required) as any
          
          formControl.checked = control.value;
          formControl.label = control.label
          formControl.key = control.key
          formControl.controlType = control.controlType;
          formControl.order = control.order;
          formControl.styles = control.styles;
          formControl.search_id = control.search_id;
          //group[control.key] = formControl//Object.assign({}, formControl, {label:question.label, key:question.key, checked:question.value})
          bigGroup[name][control.key] = formControl// new FormGroup(group)
          return
        }
        else {
          let formControl = new FormControl(control.value || '') as any; //so  we  
          formControl.checked = control.value;
          formControl.label = control.label
          formControl.key = control.key
          formControl.controlType = control.controlType;
          formControl.order = control.order;
          formControl.styles = control.styles;
          formControl.search_id = control.search_id;
          //group[control.key] = formControl //Object.assign({}, formControl, {label:question.label, key:question.key, checked:question.value})
          bigGroup[name][control.key] = formControl
          return
        }


      });

      return bigGroup
    })

    const reducedArray = newFormGroupArray.reduce((acc, it) => {
      const propName = Object.getOwnPropertyNames(it)[0]
      acc[propName] =  new FormGroup(it[propName])
      
      return acc;
    }, {});
    
    const formattedForm = new FormGroup(reducedArray)//.getRawValue()

    return formattedForm
  }

  //Function gets formValues and converts them to a format for MatTableColumns
  convertAdvancedSearchFormToTableColumns(tableColumns: any,  genericGroup: any) { //IMatTableColumn[],
    const bigGroup: any = {}
    const genericGroupNames = Object.getOwnPropertyNames(genericGroup)
    genericGroupNames.filter(groupName => groupName !== 'searchVariables').map(groupName => {
      const group: any = {};
      const groupArray = genericGroup[groupName];
      const findIndexes:Array<any> =[];

      groupArray.map((entry:any, index:number) => {
        
        let setValue: boolean;
        findIndexes[index] = tableColumns.findIndex((columnEntry:any) => columnEntry.category === groupName && columnEntry.key === entry.key)
        if (findIndexes[index] > -1) {

          setValue = true;  //Could grab the actual value using index. 
          // eslint-disable-next-line no-constant-binary-expression
          const formControl = new FormControl(true || '') as any; //so  we  
          formControl.checked = true;
          formControl.label = entry.label
          formControl.minWidth = entry.minWidth
          formControl.key = entry.key
          formControl.controlType = entry.controlType;
          formControl.order = entry.order;
          //formControl.styles = entry.styles;
          group[entry.key] = formControl //Object.assign({}, formControl, {label:question.label, key:question.key, checked:question.value})
          bigGroup[entry.category] = new FormGroup(group)
        } else {
          const formControl = new FormControl(entry.value || '') as any; //so  we  
          formControl.checked = false;
          formControl.minWidth = entry.minWidth
          formControl.label = entry.label
          formControl.key = entry.key
          formControl.controlType = entry.controlType;
          formControl.order = entry.order;
          //formControls.styles = entry.styles;
          group[entry.key] = formControl //Object.assign({}, formControl, {label:question.label, key:question.key, checked:question.value})
          bigGroup[entry.category] = new FormGroup(group)
        }


      })


    })
    const newForm = new FormGroup(bigGroup)

    return newForm
  }

}